import React from "react";
import * as Mui from "@mui/material";
import * as Muicons from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import QRReader from "react-qr-reader";
import Webcam from "react-webcam";
import IdealStandardWhite from "./images/idealstandard-white.png";
import { QuestionType, AppData } from "./AppData";

const useStyles = makeStyles((theme) => ({
  nostyle: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  container: {
    padding: "16px"
  }
}));


/*************************
 * APPLICATION COMPONENT *
 *************************/

function App() {
  const classes = useStyles();

  const storage = localStorage.getItem("UserName");
  const [user, setUser] = React.useState(storage === null ? "" : storage);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [showZoneScan, setShowZoneScan] = React.useState(false);
  const [zone, setZone] = React.useState([]);
  const [showUserScan, setShowUserScan] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [showFinish, setShowFinish] = React.useState("");
  const [showQRCode, setShowQRCode] = React.useState("");
  const [qrImageUrl, setQRImageUrl] = React.useState(null);
  const generateQRCode = () => {
    QRCode.toDataURL(user, { width: 256 }, (err, url) => {
      if (err) {
        console.error(err);
      }
      setQRImageUrl(url);
    });
  };
  const [appData, setAppData] = React.useState(AppData);
  const [activeStep, setActiveStep] = React.useState(0);
  const [showWebcam, setShowWebcam] = React.useState(false);
  const webcamRef = React.useRef(null);
  const [webcamImageUrl, setWebcamImageUrl] = React.useState(null);

  const printToPDF = () => {
    let element = document.getElementById("БТОС");
    let clientRect = element.getBoundingClientRect();
    let aspectRatio = clientRect.height / clientRect.width;
    const pdf = new jsPDF({ unit: "px" });

    html2canvas(element, { width: clientRect.width, height: clientRect.height })
      .then(canvas => {
        const imageData = canvas.toDataURL("image/png");
        const width = pdf.internal.pageSize.getWidth();
        const height = aspectRatio * width;
        pdf.addImage(imageData, "PNG", 0, 0, width / aspectRatio, height / aspectRatio);
        pdf.save("doc.pdf");
      });
  };

  return (
    <React.Fragment className={classes.container}>
      <Mui.AppBar>
        <Mui.Toolbar>
          <Mui.IconButton disableRipple>
            <img src={IdealStandardWhite} height={48} />
          </Mui.IconButton>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Toolbar />
      {!appData.btos.enabled && <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1}>
        <Mui.Card className={classes.container}>
          <Mui.CardHeader title="Определяне на одитори" />
          <Mui.CardContent>
            <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1}>
              <Mui.Grid item>
                <Muicons.AccountCircleRounded color="primary" />
              </Mui.Grid>
              <Mui.Grid item>
                <Mui.TextField error={user ? false : true} variant="outlined" label="Име на одитор" value={user} onChange={event => {
                  setUser(event.target.value);
                  generateQRCode();
                }} />
              </Mui.Grid>
              <Mui.Grid item>
                <Mui.Button color="primary" variant="contained" size="medium" startIcon={<Muicons.SaveRounded />} onClick={() => {
                  if (user) {
                    setShowSnackbar(true);
                    localStorage.setItem("UserName", user);
                  }
                }}>
                  Запази
                </Mui.Button>
                <Mui.Snackbar anchorOrigin={{ horizontal: "center", vertical: "bottom" }} open={showSnackbar} autoHideDuration={3000} onClose={() => { setShowSnackbar(false); }}>
                  <Mui.Alert severity="info" onClose={() => {
                    setShowSnackbar(false);
                  }}>
                    {`${user} запазено!`}
                  </Mui.Alert>
                </Mui.Snackbar>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1} direction="column">
              <Mui.Grid item>
                <Mui.Typography>
                  Водещ одитор ли сте?
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item>
                <Mui.RadioGroup value={showQRCode} onChange={event => {
                  const value = event.target.value;
                  setShowQRCode(value);
                  if (value === "yes") {
                    generateQRCode();
                  }
                }}>
                  <Mui.FormControlLabel value="no" control={<Mui.Radio color="primary" />} label="Да." />
                  <Mui.FormControlLabel value="yes" control={<Mui.Radio color="primary" />} label="Не." />
                </Mui.RadioGroup>
              </Mui.Grid>
            </Mui.Grid>

            {showQRCode === "no" && showZoneScan && <QRReader delay={1000} onError={err => {
              if (err) console.log(err);
            }} onScan={data => {
              if (data) {
                const index = data.substring(1);
                let zone = "";
                try {
                  zone = appData.zones.data[parseInt(index)]
                } catch (error) {
                  zone = data;
                }
                setZone(zone);
                setShowZoneScan(false);
                setActiveStep(prevActiveStep => prevActiveStep + 1);
              }
            }} />}

            {showQRCode === "no" && <Mui.Stepper orientation="vertical" activeStep={activeStep}>
              {/* Select zone */}
              <Mui.Step>
                <Mui.StepLabel>
                  Избиране на зона за одитиране
                </Mui.StepLabel>
                <Mui.StepContent>
                  <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1}>
                    <Mui.Grid item>
                      <Mui.Button color="primary" variant="contained" startIcon={<Muicons.QrCodeScannerRounded />} onClick={() => { setShowZoneScan(true); }}>
                        Сканирай
                      </Mui.Button>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.StepContent>
              </Mui.Step>
              {/* Select auditor(s) */}
              <Mui.Step>
                <Mui.StepLabel>
                  Добавяне на одитори
                </Mui.StepLabel>
                <Mui.StepContent>
                  <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1} direction="column">
                    <Mui.Grid item>
                      <Mui.Chip color="primary" icon={<Muicons.LocationOnRounded />} label={zone} />
                    </Mui.Grid>
                    <Mui.Grid item>
                      <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1} direction="column">
                        <Mui.Grid item>
                          <Mui.Grid container alignItems="center" justifyContent="center" spacing={1}>
                            <Mui.Grid item>
                              <Mui.Typography>
                                Одитори
                              </Mui.Typography>
                            </Mui.Grid>
                            <Mui.Grid item>
                              <Mui.Badge showZero color={users.length > 0 ? "primary" : "error"} badgeContent={users.length}>
                                <Muicons.GroupRounded color="primary" />
                              </Mui.Badge>
                            </Mui.Grid>
                          </Mui.Grid>
                        </Mui.Grid>
                        {users.length > 0 && <Mui.Grid item>
                          <Mui.Grid container alignItems="center" justifyContent="center" spacing={1}>
                            {users.map((value, index) => {
                              return (
                                <Mui.Grid item key={index}>
                                  <Mui.Chip color="primary" variant="outlined" icon={<Muicons.AccountCircleRounded />} label={value} />
                                </Mui.Grid>
                              );
                            })}
                          </Mui.Grid>
                        </Mui.Grid>}
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid item>
                      <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1}>
                        <Mui.Grid item>
                          <Mui.Button color="primary" variant="contained" startIcon={<Muicons.PersonAddRounded />} onClick={event => {
                            setShowUserScan(true);
                          }}>
                            Добави
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1} direction="column">
                      <Mui.Grid item>
                        <Mui.Typography>
                          Поканихте ли представител на процеса?
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item>
                        <Mui.RadioGroup value={showFinish} onChange={event => {
                          const value = event.target.value;
                          setShowFinish(value);
                        }}>
                          <Mui.FormControlLabel value="yes" control={<Mui.Radio color="primary" />} label="Да." />
                          <Mui.FormControlLabel value="no" control={<Mui.Radio color="primary" />} label="Не." />
                        </Mui.RadioGroup>
                      </Mui.Grid>
                    </Mui.Grid>
                    {showFinish === "yes" && <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1}>
                      <Mui.Grid item>
                        <Mui.Button color="primary" variant="contained" startIcon={<Muicons.CheckCircleRounded />} onClick={event => {
                          const data = appData.btos;
                          data.enabled = true;
                          setAppData({ btos: data, ...appData });
                        }}>
                          Готово
                        </Mui.Button>
                      </Mui.Grid>
                    </Mui.Grid>}
                  </Mui.Grid>
                </Mui.StepContent>
              </Mui.Step>
            </Mui.Stepper>}

            {showQRCode === "no" && showUserScan && <QRReader delay={1000} onError={err => {
              if (err) {
                console.log(err);
              }
            }} onScan={data => {
              if (data) {
                setUsers(old => [...old, data]);
                setShowUserScan(false);
              }
            }} />}

            {/* User QR code */}
            {showQRCode === "yes" && qrImageUrl !== null && <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1}>
              <Mui.Grid item>
                <img src={qrImageUrl} />
              </Mui.Grid>
            </Mui.Grid>}
          </Mui.CardContent>
        </Mui.Card>
      </Mui.Grid>}

      {appData.btos.enabled && <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1}>
        <Mui.Card id="5S" className={classes.container}>
          <Mui.Grid container className={classes.container} spacing={1} direction="column">
            <Mui.Grid item>
              <Mui.Typography variant="h6" style={{ fontWeight: 600 }}>
                БТОС
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item>
              <Mui.Grid container className={classes.container} spacing={1} direction="column">
                {appData.btos.categories.map((category, x) => {
                  return (
                    <React.Fragment>
                      <Mui.Grid item>
                        <Mui.Typography>
                          {`${x + 1}. ${category.label}:`}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item>
                        <Mui.Grid container className={classes.container} spacing={1} direction="column">
                          {category.questions.map((question, y) => {
                            return (
                              <Mui.Grid item>
                                <Mui.FormControl disabled={x === 0 && y === 0 ? false : (y === 0 ? (appData.btos.categories[x - 1].questions[appData.btos.categories[x - 1].questions.length - 1].value === "" ? true : false) : (category.questions[y - 1].value === "" ? true : false))} focused={question.value === "yes"} error={question.value === "no"}>
                                  <Mui.FormLabel>
                                    {`${x + 1}.${y + 1}. ${question.label}`}
                                  </Mui.FormLabel>
                                  <Mui.RadioGroup row value={question.value} onChange={event => {
                                    const btos = appData.btos;
                                    const currentQuestion = btos.categories[x].questions[y];
                                    const newValue = event.target.value;

                                    if (currentQuestion.value === "" && newValue === "no") {
                                      btos.points -= currentQuestion.points;
                                    } else if (currentQuestion.value === "yes" && newValue === "no") {
                                      btos.points -= currentQuestion.points;
                                    } else if (newValue === "yes" && currentQuestion.value === "no") {
                                      btos.points += currentQuestion.points;
                                    }
                                    console.log(`points: ${btos.points}`);

                                    if (currentQuestion.type === QuestionType.CRITICAL) {
                                      if (currentQuestion.value === "" && newValue === "no") {
                                        btos.criticalQuestionCount++;
                                      } else if (currentQuestion.value === "yes" && newValue === "no") {
                                        btos.criticalQuestionCount++;
                                      } else if (newValue === "yes" && currentQuestion.value === "no") {
                                        btos.criticalQuestionCount--;
                                      }
                                    }
                                    console.log(`critical question count: ${btos.criticalQuestionCount}`);

                                    btos.categories[x].questions[y].value = newValue;
                                    btos.answeredQuestions++;

                                    const pets = appData.pets;
                                    if (btos.answeredQuestions === btos.questionCount) {
                                      pets.enabled = true;
                                    }

                                    if (btos.criticalQuestionCount > 0) {
                                      pets.categories[0].questions[0].value = "no";
                                      for (let i = 0; i < pets.categories.length; i++) {
                                        for (let j = 1; j < pets.categories[i].questions.length; j++) {
                                          pets.categories[i].questions[j].value = "";
                                        }
                                      }
                                      pets.points = 0.0;
                                    } else {
                                      pets.categories[0].questions[0].value = "yes";
                                      pets.points = pets.categories[0].questions[0].points;
                                    }

                                    setAppData({ btos: btos, pets: pets });
                                    console.log(appData);
                                  }}>
                                    <Mui.FormControlLabel value="yes" control={<Mui.Radio color={question.value === "no" ? "error" : "primary"} />} label="Да." />
                                    <Mui.FormControlLabel value="no" control={<Mui.Radio color={question.value === "no" ? "error" : "primary"} />} label="Не." />
                                  </Mui.RadioGroup>
                                </Mui.FormControl>
                              </Mui.Grid>
                            );
                          })}
                        </Mui.Grid>
                      </Mui.Grid>
                    </React.Fragment>
                  );
                })}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid item>
              <Mui.Typography variant="h6" style={{ fontWeight: 600 }}>
                {`Точки: ${appData.btos.points.toFixed(2)}`}
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Card>
      </Mui.Grid>}

      {appData.pets.enabled && <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1}>
        <Mui.Card id="5S" className={classes.container}>
          <Mui.Grid container className={classes.container} spacing={3}>
            <Mui.Grid item>
              <Mui.Typography variant="h6" style={{ fontWeight: 600 }}>
                5S
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item>
              <Mui.Grid container className={classes.container} spacing={1} direction="column">
                {appData.pets.categories.map((cat, x) => {
                  return (
                    <Mui.Grid container spacing={1}>
                      <Mui.Grid item>
                        <Mui.Typography>
                          {`${x + 1}. ${cat.label}:`}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item>

                        {cat.questions.map((question, y) => {
                          return (
                            <React.Fragment>
                              <Mui.FormControl disabled={x === 0 && y === 0 ? false : (y === 0 ? (x !== 0 ? (appData.pets.categories[x - 1].questions[appData.pets.categories[x - 1].questions.length - 1].value === "yes" ? false : true) : true) : (cat.questions[y - 1].value === "yes" ? false : true))} focused={question.value === "yes"} error={question.value === "no"}>
                                <Mui.Grid container className={classes.container} spacing={1} direction="column">
                                  <Mui.Grid item>
                                    <Mui.FormLabel>
                                      {`${x + 1}.${y + 1}. ${question.label}`}
                                    </Mui.FormLabel>
                                  </Mui.Grid>
                                  <Mui.Grid item>
                                    <Mui.RadioGroup row value={question.value} onChange={event => {
                                      const data = appData.pets;
                                      if (data.categories[x].questions[y].value === "") {
                                        if (event.target.value === "yes") {
                                          data.points += question.points;
                                        }
                                      } else {
                                        if (event.target.value === "yes") {
                                          data.points += question.points;
                                        } else {
                                          data.points -= question.points;
                                        }
                                      }
                                      data.categories[x].questions[y].value = event.target.value;
                                      setAppData({ ...appData, pets: data });
                                    }}>
                                      {x === 0 && y === 0 ?
                                        (question.value === "yes" ? <Mui.FormControlLabel value="yes" control={<Mui.Radio color="primary" />} label="Да." />
                                          : <Mui.FormControlLabel value="no" control={<Mui.Radio color="error" />} label="Не." />)
                                        : <React.Fragment>
                                          <Mui.FormControlLabel value="yes" control={<Mui.Radio color={question.value === "no" ? "error" : "primary"} />} label="Да." />
                                          <Mui.FormControlLabel value="no" control={<Mui.Radio color={question.value === "no" ? "error" : "primary"} />} label="Не." />
                                        </React.Fragment>}
                                    </Mui.RadioGroup>
                                  </Mui.Grid>
                                  {question.value === "no" && <Mui.Grid item>
                                    <Mui.Typography color="error">
                                      Открити са опасности, които биха застрашили здравето и/или живота на работниците!
                                    </Mui.Typography>
                                  </Mui.Grid>}

                                  {question.value === "no" && showWebcam && <Mui.Grid item>
                                    <Webcam ref={webcamRef} height={240} screenshotFormat="image/png" screenshotQuality={1.0} videoConstraints={{ facingMode: { exact: "environment" } }} />
                                  </Mui.Grid>}

                                  {question.value === "no" && <Mui.Grid item>
                                    <Mui.Grid container spacing={1}>
                                      <Mui.Grid item>
                                        <Mui.Button color="error" variant="contained" size="medium" startIcon={<Muicons.PhotoCameraRounded />} onClick={() => {
                                          if (!showWebcam) {
                                            setShowWebcam(true);
                                          } else {
                                            setWebcamImageUrl(webcamRef.current.getScreenshot());
                                            if (!webcamImageUrl) {
                                              console.log("Webcam screenshot failed!");
                                            }
                                            setShowWebcam(false);
                                          }
                                        }}>
                                          Снимай
                                        </Mui.Button>
                                      </Mui.Grid>
                                      {showWebcam && <Mui.Grid item>
                                        <Mui.Button color="error" variant="contained" size="medium" startIcon={<Muicons.NoPhotographyRounded />} onClick={() => { setShowWebcam(false); }}>
                                          Спри
                                        </Mui.Button>
                                      </Mui.Grid>}

                                      {question.value === "no" && webcamImageUrl !== null && <Mui.Grid item>
                                        <img src={webcamImageUrl} height={240} />
                                      </Mui.Grid>}

                                    </Mui.Grid>
                                  </Mui.Grid>}
                                </Mui.Grid>
                              </Mui.FormControl>
                            </React.Fragment>
                          );
                        })}
                      </Mui.Grid>
                    </Mui.Grid>
                  );
                })}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid item>
              <Mui.Typography variant="h6" style={{ fontWeight: 600 }}>
                {`Точки: ${appData.pets.points.toFixed(2)}`}
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Card>
      </Mui.Grid>}

      {appData.btos.enabled && <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1} direction="column">
        <Mui.Grid item>
          <Mui.Typography variant="h6" style={{ fontWeight: 600 }}>
            Общо точки:
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid item>
          <Mui.Typography variant="h4" style={{ fontWeight: 600 }}>
            {(appData.btos.points + appData.pets.points).toFixed(2)}
          </Mui.Typography>
        </Mui.Grid>
      </Mui.Grid>}
      {appData.btos.enabled && <Mui.Grid container className={classes.container} alignItems="center" justifyContent="center" spacing={1}>
        <Mui.Grid item>
          <Mui.Button color="primary" variant="contained" size="large" startIcon={<Muicons.CheckCircleRounded />} onClick={() => { }}>
            Готово
          </Mui.Button>
        </Mui.Grid>
      </Mui.Grid>}
    </React.Fragment >
  );
}

export default App;