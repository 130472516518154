export const QuestionType = {
    NORMAL: "normal",
    CRITICAL: "critical"
};

export const AppData = {
    zones: {
        data: [
            "Байцване и Дегалванизиране",
            "Бояджийна",
            "Галванична дейност V2 Asmega 2",
            "Галванична дейнсот ППП Асмега 3",
            "Галванична дейност Асмега 1",
            "Декоративни покрития ",
            "Инструментална",
            "Месинг блок",
            "Механика V2",
            "Механика Екип Mazak и Dual control V1",
            "Механика Екип Риелло и Пункт обдухване V1",
            "Монтаж V1 Екип 1",
            "Монтаж V1 Екип 2",
            "Монтаж V1 Екип 3",
            "Монтаж V1 Екип 4",
            "Монтаж V2",
            "Монтаж ППП",
            "Почистване на лети заготовки",
            "Пресов V2",
            "Производство на лети заготовки",
            "Производство на сърца",
            "Роботизирано шлайфане",
            "Складово стопанство V1-Екип1",
            "Складово стопанство V1-Екип2",
            "Складово стопанство V2",
            "Стругови детайли V2",
            "Шлайф полир V1 Екип 1",
            "Шлайф полир V1 Екип 2",
            "Шлайф полир V1 Екип 3",
            "Шлайф полир V1 Екип 4",
            "Шлайф полир V1 Екип 5 - Малък полир",
            "Шлайф полир V2",
            "Шприцове ППП"
        ]
    },
    btos: {
        enabled: false,
        criticalQuestionCount: 0,
        questionCount: 5 * 5,
        categories: [{
            label: "Защита при авария и инциденти",
            questions: [{
                label: "Наличен ли e евакуационния план с обозначен евакуационен маршрут, авариен изход и сборен пункт?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Аварийните маршрути и изходи проходими ли са?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Обозначен ли е сборния пункт на процеса в случай на евакуация (проверете има ли стикер/табела)?",
                value: "",
                points: 0.2,
                type: QuestionType.NORMAL
            },
            {
                label: "Има ли в процеса определени лица за действия в ситуация на авария? Обучени ли са?",
                value: "",
                points: 0.2,
                type: QuestionType.NORMAL
            },
            {
                label: "Има ли свободен достъп до аптечните кутии? Има ли светещ, работеш таблет за докладване за инциденти и почтии инциденти и дали ключалката на кутията е заключена?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            }]
        },
        {
            label: "Пожаробезопасност",
            questions: [{
                label: "Има ли свободен достъп до средствата за пожарогасене (пожарогасител, вътрешно противопожарен кран) и пожароизвестяване (бутон за задействане на аларма)?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Обозначени ли са местата за средствата за пожарогасене (налични ли са обозначителните стикери)?",
                value: "",
                points: 0.2,
                type: QuestionType.NORMAL
            },
            {
                label: "Годни ли са средствата за пожарогасене (проверете стикерите)?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Свободно достъпни ли са електрическите табла (общите табла за процеса без тези на машините)? Общите електрически табла  свободни ли са от всякакви предмети (без тези на машините) - особено шишета със вода, които биха могли да предизвикат инцидент?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Нанесена ли е необходимата маркировка на пода, ограничаваща поставянето на предмети пред средствата за пожарогасене и електрическите табла?",
                value: "",
                points: 0.2,
                type: QuestionType.NORMAL
            }]
        },
        {
            label: "Безопастност на работното място",
            questions: [{
                label: "Има ли инструкция за безопасна работа на работното място (на видно място ли е)?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Носят ли се личните предпазни средства, съгласно оценката на риска?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Работи ли осветлението предназначено на конкретното работното място?",
                value: "",
                points: 0.2,
                type: QuestionType.NORMAL
            },
            {
                label: "Работната зона свободна ли е от материали извън обозначените позиции, които е възможно да предизвикат инцидент? Има ли свободен достъп до пешеходните зони и пътеки за ВЗТ (вътрешно заводски транспорт). Няма нищо, което да пречи (при необходимост налични ли са  всички перапети и визуално изправнили са всички стапала)?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Чиста ли е зоната под и около машината (проверете за замърсявания от масла и видими течове)? Обезопасени ли са всички части на машината (да няма висящи или липсващи капаци и съответните да са затворени да няма необезопасени и висящи кабели и маркучи)?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            }]
        },
        {
            label: "Употреба и съхранение на материалите",
            questions: [{
                label: "Има ли всички необходими информационни листи за безопастност на използваните материали в процеса?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Има ли всички инструкции за безопасна работа с химични вещества?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Има ли събирателна вана под съдовете с течни химични вещества?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Има ли обозначения на български език и пиктограма на опаковките с химични вещества (всичко различно от вода)?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Безопасно ли са подредени  материалите в процеса и спазена ли е указаната максимална височина (опасност от падащи опаковки)? Има ли стикер за товароносимост на стелажи?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            }]
        },
        {
            label: "Вътрешно заводски транспорт и защита опазване на околната среда",
            questions: [{
                label: "Отговаря ли състоянието на ВЗТ на стандарта и попълнен ли е чек листа (проверка за неизпправности - водете се по помощната инструкция специализирана за участъка)?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Има ли стикер за товароносимост на ел. Колички ръчни колички и мотокари?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Носи ли водачът задължителната светлоотразителна жилетка и предпазен колан? Правоспособен ли е водачът на ВЗТ (проверка на свидетелство)?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Има ли всички необходими съдове за отпадъци, които се генерират в участъка? Налични ли са съберателните вани към съответните отпадъци има ли обозначение на всички съдове за отпадаци с вида и номера на отпадъка (водете се по помощната инструкция специализирана за участъка)?",
                value: "",
                points: 0.2,
                type: QuestionType.CRITICAL
            },
            {
                label: "Изхвърлените отпадъци отговарят ли на вида на отпадъка, който е определен за този контейнер (проверете дали има смесване на отпадъците)?",
                value: "",
                points: 0.2,
                type: QuestionType.NORMAL
            }]
        }],
        answeredQuestions: 0,
        points: 5.0
    },
    pets: {
        enabled: false,
        categories: [{
            label: "Ниво - Сортирано",
            questions: [{
                label: "Не са открити опасности, които биха застрашили здравето и живота на работниците.",
                value: "",
                points: 0.33
            },
            {
                label: "Налични са всички предмети и инструменти, необходими за ежедневната работа (разговор с операторите).",
                value: "",
                points: 0.33
            },
            {
                label: "Отстранено ли е всичко от работното място, което не е необходимо за ежедневната работа. Всичко, което е за бракуване и ремонт е ясно обозначено.",
                value: "",
                points: 0.34
            }]
        },
        {
            label: "Ниво - Подредено",
            questions: [{
                label: "Всички компоненти и материали са обозначени със съответния им номер (паспорт, етикет, канбан карти и т.н.).",
                value: "",
                points: 0.33
            },
            {
                label: "Определени са места за съхраняване на всичко - материали, инструменти, документация, лични вещи и т.н.",
                value: "",
                points: 0.33
            },
            {
                label: "Всичко се съхранява на определените за тях места - материали, инструменти, документация, лични вещи и т.н.",
                value: "",
                points: 0.34
            }]
        },
        {
            label: "Ниво - Почистено",
            questions: [{
                label: "Зоната, пода, стелажите  и работното място са напълно почистени от прах, разливи и отпадъци.",
                value: "",
                points: 0.33
            },
            {
                label: "Машината, оборудването и инстументите са напълно почистено от прах и замърсявания. Влючително всички съдове за съхранение.",
                value: "",
                points: 0.33
            },
            {
                label: "Оборудването е добре поддържано и работи (разговор с оператор). При авария трябва да бъде видимо обозначено (поставена е табела).",
                value: "",
                points: 0.34
            }]
        },
        {
            label: "Ниво - Стандартизирано",
            questions: [{
                label: "Всички инструменти и документи са надписани (с етикет, сянка и т.н).",
                value: "",
                points: 0.33
            },
            {
                label: "Налична е маркировка на всички зони на работното място и около него, включително ясно обозначено място за брак (червена лента,червена зона, стикер).",
                value: "",
                points: 0.33
            },
            {
                label: "5S стандартът заедно с плана за почистване и поддържане са налични и са закачени на видимо място в зоната.",
                value: "",
                points: 0.34
            }]
        },
        {
            label: "Ниво - Поддържано",
            questions: [{
                label: "Зоната се одитира и оценява поне веднъж месечно (наличен е чек лист от проведен одит от предходния месец).",
                value: "",
                points: 0.33
            },
            {
                label: "Отбелязани са отклоненията, определени са и са възложени действия за подобрения (какво, кой, кога).",
                value: "",
                points: 0.33
            },
            {
                label: "На таблото е наличен поне един активен или завършен кайзен (мероприятия за отстраняване на отклонения и генериране на подобрения).",
                value: "",
                points: 0.34
            }]
        }],
        points: 0.0
    }
};